@import '~@andes/snackbar/index';

.category-menu-top {
  &-name-button {
    all: unset;
  }

  &-icon {
    height: $search-spacing-16;
    width: $search-spacing-16;

    &.up {
      transform: rotate(270deg);
    }

    &.down {
      transform: rotate(90deg);
    }

    use {
      fill: $search-gray-90;
    }
  }

  &-content {
    box-shadow: 0 $search-spacing-1 $search-spacing-2 $search-gray-120;
    box-sizing: border-box;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    z-index: 2;

    &-menu {
      box-shadow: 0 $search-spacing-18 $search-spacing-16 $search-gray-r025;
      position: absolute;
      background: $search-gray-200;
      z-index: 2;
      top: 100%;
      width: 100%;

      &.not-display {
        display: none;
      }

      &-anchor-active {
        margin-left: -$search-spacing-2;
      }

      &-anchor-not-active {
        color: $andes-gray-900;
      }

      .children-categories {
        max-height: 90vh;
        overflow: scroll;
      }

      ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: $search-spacing-14;
        font-weight: $font-weight-normal;

        li,
        a,
        p {
          font-size: $search-spacing-16;
          line-height: $search-spacing-20;
          display: block;
          padding-bottom: $search-spacing-14;
          padding-top: $search-spacing-14;
          text-decoration: none;
          margin: 0;
          color: $andes-black;
        }
      }

      &-level-0 {
        padding-left: $search-spacing-16;
      }

      &-level-1 {
        padding-left: $search-spacing-16 * 2;
      }

      &-level-2 {
        padding-left: $search-spacing-16 * 3;
      }

      &-level-3 {
        padding-left: $search-spacing-16 * 4;
      }

      &-level-4 {
        padding-left: $search-spacing-16 * 5;
      }

      &-level-5 {
        padding-left: $search-spacing-16 * 6;
      }

      &-level-6 {
        padding-left: $search-spacing-16 * 7;
      }

      &-level-7 {
        padding-left: $search-spacing-16 * 8;
      }
    }
  }

  &-toggle-button {
    text-align: left;
    padding: $search-spacing-16;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid $search-gray-93;
    background-color: $andes-white;
    width: 100%;
    box-sizing: border-box;
    font-family: $font-family-primary;
    margin: 0;
    color: $andes-black;

    &-title {
      display: block;
      font-size: $font-size-12;
      line-height: $search-spacing-14;
      font-weight: $font-weight-light;
    }

    &-category-name > svg {
      position: relative;
      top: $search-spacing-3;
      left: $search-spacing-3;
    }
  }
}

.#{$category-trends-prefix}-mobile-category-menu-top {
  .category-menu-top-content-menu-element-active {
    border-left: $search-spacing-2 solid $andes-blue-500;
  }

  .category-menu-top-content-menu-element-active a {
    color: $andes-blue-500;
  }
}

.#{$best-seller-prefix}-category-menu-top {
  &-webview {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $andes-black;
  }

  .category-menu-top-content-menu-element-active {
    border-left: $search-spacing-2 solid $andes-orange-500;
    background: $search-gray-5;
  }

  .category-menu-top-content-menu-element-active a {
    color: $andes-orange-500;
  }
}
