.ui-search-layout--grid__item {
  &.poly-card.poly-card--mobile {
    --poly-general-border-bottom: 1px solid #ededed;
    --poly-general-image-divider: unset;
    --poly-max-width: 100%;
  }

  &.poly-card.poly-card--mobile.poly-card--grid:nth-of-type(odd) {
    --poly-general-border-right: 1px solid #ededed;
  }

  &.poly-card--grid.poly-card--mobile {
    .poly-card__portada--grid-height {
      height: 200px;
    }
  }
}

.item-search {
  .andes-card {
    border-radius: 0;
  }

  .ui-search-layout__item {
    width: 100% !important;
  }
}
