@import './items.mobile';

.ui-search-layout--grid {
  &__container {

    .andes-card__content {
      padding: 0 !important;
    }

    .andes-card__footer--link {
      line-height: 18px;
      font-size: 14px;
    }

    .andes-card__button {
      all: unset;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: $andes-blue-500;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 0fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    .ui-search-layout--grid {
      grid-template-columns: auto;
    }

    @media (width <= 275px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }
  }

  &__button {
    margin-top: 10px;
  }
}
