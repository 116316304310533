// sass-lint:disable no-misspelled-properties
@import '../../../styles/variables';
@import '../../../styles/common';
@import 'app/components-v2/header/styles/categories-header';
@import '../../../components-v2/category-top-menu/styles';
@import '../../../components-v2/category-trends-expandable/styles/category-trends-expandable';
@import '../../../components-v2/category-trend-container/category-trend-container';
@import '~@andes/common/index';
@import '~@andes/card/index';
@import '~@andes/thumbnail/index';
@import '~@andes/button/index';
@import '~@andes/money-amount/index';
@import '~@andes/badge/index';
@import '~@andes/carousel-free/index';
@import './polycard';
@import '../../../components/grid-layout/styles/container-items.mobile';
@import '../../../components/card/attributes/styles/attributes.mobile';
@import '../../../components/card/highlight/styles/highlight-label.mobile';
@import '../../../components/layout/styles/layout.mobile';
@import '../../../components/layout/styles/grid.mobile';
@import '../../../components/card/price/styles/price.mobile';
@import '../../../components/card/fulfillment/styles/fulfillment';

@import '~@seo-frontend-components/card-blog-carousel-mobile/index';
@import '~@seo-frontend-components/card-blog-entry/index';
@import '../../../components/seo-contents/styles/seo-contents.mobile';
@import '../../../components-v2/footer-components/styles/mobile';

body {
  background-color: $andes-gray-070-solid;
}

.ui-category-trends-mobile-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 10px;

  // ad-hoc adjustment for card images in motors category-trends
  .ui-search-layout--grid__container {
    .ui-search-result--mot__item-image-container img {
      aspect-ratio: 3/2;
      object-fit: cover;
    }
  }
}
